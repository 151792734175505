import React from 'react';
import { useSelector } from 'react-redux';

import { isEmpty } from 'react-redux-firebase';
import { Container, Header, Grid, Divider } from 'semantic-ui-react';
import { SignedIn } from './SignedIn'
import { SignInModal } from './SignedInModal';
import { Link } from 'react-router-dom';

export const Layout = (props) => {
  const auth = useSelector((state) => state.firebase.auth)

  return (
    <>
      <Header style={{ marginTop: '0.75em' }}>
        <Container>
          <Grid>
            <Grid.Row columns={2} verticalAlign="middle">
              <Grid.Column>
                <Link to='/'>CHORTLE.</Link>
                <Header.Subheader>The Chores &amp; Rewards Portal</Header.Subheader>
              </Grid.Column>

              <Grid.Column textAlign="right">
                {isEmpty(auth) ? (
                  <SignInModal {...props} />
                ) : (
                  <SignedIn {...props} />
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </Header>

      <Divider />

      <Container>
        {props.children}
      </Container>
    </>
  )
}