import React from 'react';
import { useSelector } from 'react-redux';
import { useFirestoreConnect, isEmpty, isLoaded } from 'react-redux-firebase';
import { Link } from 'react-router-dom';

export const Kids = () => {
  useFirestoreConnect([
    'kids',
  ])

  const { kids } = useSelector((state) => state.firestore.ordered)
  
  if (!isLoaded(kids)) { return <div>Loading...</div> }

  return (
    <div>
      <h3>Kids.</h3>

      {isEmpty(kids) ? (
        <p>No kids found</p>
      ) : (
        <ul>
          {kids.map((kid) =>
            <li key={kid.id}>
              <Link to={`/kids/${kid.id}`}>{kid.name}</Link>
            </li>
          )}
        </ul>
      )}
    </div>
  )
}