import React from 'react'
import { useSelector } from 'react-redux'
import { useFirebase } from 'react-redux-firebase'
import { useHistory } from 'react-router-dom'
import { Header } from 'semantic-ui-react'

export const SignedIn = (props) => {
  const firebase = useFirebase()
  const history = useHistory()
  const auth = useSelector(state => state.firebase.auth)

  if (!auth.isLoaded) return null

  const handleSignOut = (e) => {
    e.preventDefault()
    firebase.logout()
    history.push('/')
  }

  return (
    <Header.Subheader>
      signed in as {auth.email} | <a  href="#sign-out" onClick={handleSignOut}>sign out</a>
    </Header.Subheader>
  )
}

export default SignedIn
