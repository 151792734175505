import React, { useState } from 'react'

import {
  Button,
  Form,
  Header,
  Icon,
  Modal
} from 'semantic-ui-react'
import styled from 'styled-components'
import { useFirebase } from 'react-redux-firebase'
import { useSelector } from 'react-redux'

const Error = styled.p`
  color: #900;
`

export const SignInModal = (props) => {
  const firebase = useFirebase()
  const { auth, authError } = useSelector(state => state.firebase)

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const handleSignIn = (e) => {
    e.preventDefault()
    firebase.login({ email, password })
  }

  return (
    <Modal trigger={<Button>Sign In</Button>} size="mini" closeIcon>
      <Header icon='user' content='Sign In' />
      <Modal.Content>
        {authError &&
          <React.Fragment>
            <h5>Login Error:</h5>
            <Error>{authError.message}</Error>
          </React.Fragment>
        }

        <Form onSubmit={handleSignIn}>
          <Form.Field>
            <Form.Input autoFocus type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email" />
          </Form.Field>

          <Form.Field>
            <Form.Input type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Password" />
          </Form.Field>

          <input type="submit" style={{ visibility: 'hidden', position: 'absolute' }} />
        </Form>
      </Modal.Content>

      <Modal.Actions>
        <Button color='green' onClick={handleSignIn} loading={auth.isLoading}>
          <Icon name='checkmark' /> Sign In
        </Button>
      </Modal.Actions>
    </Modal>
  )
} 
