import React, { useState } from 'react'
import { AsBux } from '../AsBux'
import { Icon, Header, Grid, Button, Label } from 'semantic-ui-react'

export const RewardForm = ({ reward, onComplete, onUncomplete, onChange }) => {
  const [rewardAmount, setRewardAmount] = useState(0)

  const handleChangeAmount = (changeBy) => {
    if (rewardAmount === 0 && changeBy < 0) {
      return
    }
    
    setRewardAmount(rewardAmount + changeBy)

    changeBy > 0 ? onComplete(reward) : onUncomplete(reward)

    const rewardFormData = {
      ...reward,
      quantity: rewardAmount + changeBy
    }

    onChange(rewardFormData)
  }

  return (
    <Header block>
      <Grid>
        <Grid.Row columns={3} verticalAlign="middle">
          <Grid.Column>{reward.name}</Grid.Column>
          
          <Grid.Column textAlign="center">
            <Button icon size="mini" onClick={() => handleChangeAmount(-1)}>
              <Icon name="minus" color="red" />
            </Button>
            
            <Label>
              {rewardAmount}
            </Label>
            
            <Button icon size="mini" onClick={() => handleChangeAmount(1)}>
              <Icon name="add" color="green" />
            </Button>
          </Grid.Column>

          <Grid.Column textAlign="right">
            <AsBux>{reward.value}</AsBux>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Header>
  )
}