import React, { useState, useEffect } from 'react'
import { AsBux } from '../AsBux'
import { Form, Icon, Header, Grid } from 'semantic-ui-react'

export const ChoreForm = ({ chore, kid, onComplete, onUncomplete, onChange }) => {
  // eslint
  const [choreCompleted, setChoreCompleted] = useState(false)
  const [tasksCompleted, setTasksCompleted] = useState([])

  /* eslint-disable react-hooks/exhaustive-deps */

  useEffect(() => {
    if (tasksCompleted.length === chore.tasks.length) {
      setChoreCompleted(true)
    } else {
      if (choreCompleted) {
        setChoreCompleted(false)
      }
    }
  }, [tasksCompleted])

  useEffect(() => {
    if (choreCompleted === true) {
      onComplete(chore)
    } else {
      if (tasksCompleted.length !== 0) {
        onUncomplete(chore)
      }
    }
  }, [choreCompleted])

  /* eslint-enable react-hooks/exhaustive-deps */

  const handleCompletedTask = (d) => {
    const tasks = [...tasksCompleted]
    if (d.checked) {
      tasks.push(d.name)
    } else {
      tasks.splice(tasks.indexOf(d.name), 1)
    }

    setTasksCompleted(tasks)

    const tasksAsBooleans = {}
    chore.tasks.forEach((task) => { tasksAsBooleans[task] = false })
    tasks.forEach((task) => { tasksAsBooleans[task] = true })

    const choreFormData = {
      ...chore,
      completed: tasks.length === chore.tasks.length,
      tasks: tasksAsBooleans
    }

    onChange(choreFormData)
  }

  let headerColor = 'black'

  switch (chore.type) {
    case 'daily':
      headerColor = 'orange'
      break;

    case 'weekday':
      headerColor = 'green'
      break;

    case 'weekend':
      headerColor = 'blue'
      break;

    default:
      break;
  }

  return (
    <>
      <Header block color={headerColor}>
        <Grid>
          <Grid.Row columns={2}>
            <Grid.Column>
            {chore.type.charAt(0).toUpperCase() + chore.type.slice(1)}
            {': '}
            {chore.name}
            </Grid.Column>

            <Grid.Column textAlign="right">
              {choreCompleted ? (
                <>
                <Icon color="green" name="checkmark" />
                &nbsp;&nbsp;
                </>
              ) : null}
              <AsBux>{chore.value}</AsBux>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Header>
      {chore.tasks && chore.tasks.length > 0 ? (
        <Form>
          <Form.Field>
            {chore.tasks.map((task, index) =>
              <Form.Checkbox
                key={`${chore.id}-${index}`}
                name={task}
                label={task}
                onChange={(e, d) => handleCompletedTask(d)}
              />
            )}
          </Form.Field>
        </Form>
      ) : (
        <p>No tasks found for this chore.</p>
      )}
    </>
  )
}