import React from 'react'
import { Statistic, Icon } from 'semantic-ui-react'
import { AsBux } from '../AsBux'

export const Stats = ({ kid, charts }) => {
  let allChoresCompleted = 0
  let allTasksCompleted = 0
  let allRewardsQuantity = 0

  charts.forEach((chart) => {
    allChoresCompleted = allChoresCompleted + parseFloat(chart.summary.total_chores_completed)
    allTasksCompleted = allTasksCompleted + parseFloat(chart.summary.total_tasks_completed)
    allRewardsQuantity = allRewardsQuantity + parseFloat(chart.summary.total_rewards_collected)
  })

  return (
    <Statistic.Group widths='four' size="small">
      <Statistic color={kid.balance < 0 ? 'red' : 'green'}>
        <Statistic.Value><AsBux>{kid.balance}</AsBux></Statistic.Value>
        <Statistic.Label>Balance</Statistic.Label>
      </Statistic>

      <Statistic>
        <Statistic.Value>{allChoresCompleted}</Statistic.Value>
        <Statistic.Label><Icon name="checkmark" color="green" /> Chores</Statistic.Label>
      </Statistic>

      <Statistic>
        <Statistic.Value>{allTasksCompleted}</Statistic.Value>
        <Statistic.Label><Icon name="checkmark" color="green" /> Tasks</Statistic.Label>
      </Statistic>

      <Statistic>
        <Statistic.Value>{allRewardsQuantity}</Statistic.Value>
        <Statistic.Label><Icon name="checkmark" color="green" /> Rewards</Statistic.Label>
      </Statistic>
    </Statistic.Group>
  )
}