import React from 'react';
import styled from 'styled-components';
import moment from 'moment';

import { Link } from 'react-router-dom';
import { AsBux } from '../AsBux';
import { Icon, Statistic } from 'semantic-ui-react';

const GridList = styled.div`
  display: grid;
  align-items: middle;
  grid-template-columns: 20px 1fr auto auto auto;
  grid-gap: 1em;
  font-weight: bold;

  a:hover {
    background: #efefef;
  }

  .statistic {
    margin: 0 !important;
    justify-self: right;

    &.spent {
      margin-right: 1em !important;
      padding-right: 2em;
      border-right: 1px solid #efefef;
    }
  }
`

export const RecentCharts = ({ kid, charts }) => {
  let weeklyEarned = 0
  let weeklySpent = 0

  if (charts.length !== 0 && charts.length === 1) {
    weeklyEarned = charts[0].summary.earned
    weeklySpent = charts[0].summary.spent
  } else if (charts.length !== 0 && charts.length > 1) {
    weeklyEarned = charts.reduce((acc, val) => {
      if (acc.id) { return acc.summary.earned + parseFloat(val.summary.earned) }
      return acc + parseFloat(val.summary.earned)
    })
  
    weeklySpent = charts.reduce((acc, val) => {
      if (acc.id) { return acc.summary.spent + parseFloat(val.summary.spent) }
      return acc + parseFloat(val.summary.spent)
    })
  }

  const weeklyDiff = weeklyEarned - weeklySpent

  return (
    <>
      {charts && charts.length === 0 ? (
        <p>No recent charts.</p>
      ) : (
        <GridList>
          <Icon name="archive" size="large" />
          <span>Weekly Summary:</span>

          <Statistic size="mini" color="green" className="earned">
            <Statistic.Value><AsBux>{weeklyEarned}</AsBux></Statistic.Value>
            <Statistic.Label>earned</Statistic.Label>
          </Statistic>

          <Statistic size="mini" color="red" className="spent">
            <Statistic.Value><AsBux>{weeklySpent}</AsBux></Statistic.Value>
            <Statistic.Label>spent</Statistic.Label>
          </Statistic>

          <Statistic size="mini" color={weeklyDiff < 0 ? 'red' : 'green'} className="diff">
            <Statistic.Value><AsBux>{weeklyDiff}</AsBux></Statistic.Value>
            <Statistic.Label>{weeklyDiff < 0 ? 'Lost' : 'Saved'}</Statistic.Label>
          </Statistic>

          {charts.map((chart) => {
            const date = moment.unix(chart.timestamp.seconds)
            const { earned, spent } = chart.summary
            const diff = earned - spent

            return (
              <React.Fragment key={chart.id}>
                {chart.notes !== '' ? (
                  <Icon name="file" size="large" title={chart.notes} />
                  ) : (
                  <Icon name="file outline" size="large" />
                )}

                <Link to={`/kids/${kid.id}/charts/${chart.id}`}>{date.format('dddd, MMMM Do')}</Link>

                <Statistic size="mini" color="green" className="earned">
                  <Statistic.Value><AsBux>{chart.summary.earned}</AsBux></Statistic.Value>
                </Statistic>

                <Statistic size="mini" color="red" className="spent">
                  <Statistic.Value><AsBux>{chart.summary.spent}</AsBux></Statistic.Value>
                </Statistic>

                <Statistic size="mini" color={diff < 0 ? 'red' : 'green'} className="diff">
                  <Statistic.Value><AsBux>{diff}</AsBux></Statistic.Value>
                </Statistic>
              </React.Fragment>
            )
          })}
        </GridList>
      )}
    </>
  )
}