import React, { useState } from 'react'
import moment from 'moment'
import { useFirestoreConnect, isLoaded, useFirestore } from 'react-redux-firebase'
import { Grid, Header, Container, Button, Icon, Menu, Segment, Form } from 'semantic-ui-react'
import { useSelector } from 'react-redux'
import { ChoreForm } from './Kid.ChoreForm'
import { AsBux } from '../AsBux'
import styled from 'styled-components'
import { RewardForm } from './Kid.RewardForm'
import { useHistory } from 'react-router'

// Styled components
// ====================================================================================================================

const FloatingHeader = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 1.75em 0;
  background: #fff;
  border-bottom: 1px solid #efefef;
  z-index: 1;
`

const ContentArea = styled.div`
  margin-bottom: 8em;
`

const FloatingFooter = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1.75em 0;
  background: #fff;
  border-top: 1px solid #efefef;
  z-index: 1;
`

export const DailyChartForm = ({ kid }) => {

  // Hooks
  // ==================================================================================================================

  const firestore = useFirestore()
  const history = useHistory()

  useFirestoreConnect([
    { collection: 'chores', orderBy: ['type'] },
    { collection: 'rewards', orderBy: ['value'] }
  ])
  const { chores, rewards } = useSelector((state) => state.firestore.ordered)

  const [saving, setSaving] = useState(false)
  const [choreBalance, setChoreBalance] = useState(0)
  const [rewardBalance, setRewardBalance] = useState(0)
  const balance = parseFloat(kid.balance) + choreBalance - rewardBalance

  const [activeTab, setActiveTab] = useState('chores')
  const [formData, setFormData] = useState({
    chores_completed: {},
    rewards_collected: {},
    notes: ''
  })

  if (!isLoaded(chores)) { return <div>Loading...</div> }

  // Data setup and event handlers
  // ==================================================================================================================

  const filteredChores = chores.filter((chore) => {
    const day = moment().format('dddd')
    let isWeekend = false

    switch (day) {
      case 'Saturday':
      case 'Sunday':
        isWeekend = true
        break;

      default:
        break;
    }

    if (isWeekend) {
      return chore.type !== 'weekday'
    }

    return chore.type !== 'weekend'
  })

  const handleChoreChanged = (chore) => {
    const data = {...formData}
    data.chores_completed[chore.id] = chore

    setFormData(data)
  }

  const handleRewardChanged = (reward) => {
    const data = {...formData}
    data.rewards_collected[reward.id] = reward

    setFormData(data)
  }

  const handleSubmitChart = async () => {
    // Set the saving state, to prevent user from clicking save multiple times
    setSaving(true)

    // Clone formData from state, so we can prep the data
    const data = {...formData}

    // Prep data for storing in a chart record
    data.timestamp = new Date()
    data.kid = kid.id
    data.tasks_completed = []
    data.rewards_collected = Object.values(data.rewards_collected)
    data.chores_completed = Object.values(data.chores_completed).map((chore) => {
      chore.tasks = Object.keys(chore.tasks).map(k => {
        if (chore.tasks[k]) { data.tasks_completed.push({ name: k }) }
        return { name: k, complete: chore.tasks[k] }
      })
      return chore
    });

    // Prep summary data
    data.summary = {
      earned: choreBalance,
      spent: rewardBalance,
      total_chores_completed: data.chores_completed.filter((c) => c.completed).length,
      total_tasks_completed: data.tasks_completed.length
    }

    // Sum all reward quantities together and add to summary
    data.summary.total_rewards_collected = 0
    data.rewards_collected.forEach((reward) => {
      data.summary.total_rewards_collected = data.summary.total_rewards_collected + reward.quantity
    })


    // Add new chart to Firestore
    await firestore.add('charts', data)
    
    // Update kid balance on Firestore
    await firestore.update(`kids/${kid.id}`, { balance })
    
    // Return to charts summary
    history.push(`/kids/${kid.id}`)
  }

  // Rendering
  // ==================================================================================================================

  return (
    <>
      <FloatingHeader>
        <Container>
          <Header>
            <Grid>
              <Grid.Row columns={3} verticalAlign="middle">
                <Grid.Column>
                  Daily Chart for {kid.name}
                </Grid.Column>

                <Grid.Column textAlign="center">
                  <Header.Subheader>
                    {moment().format('dddd, MMMM Do')}
                  </Header.Subheader>
                </Grid.Column>

                <Grid.Column textAlign="right">
                  <Header.Subheader>
                    Starting Balance:{' '}
                    <strong>
                      <AsBux>{kid.balance}</AsBux>
                    </strong>
                  </Header.Subheader>

                  <Header.Subheader>
                    New Balance:{' '}
                    <strong style={{ color: balance >= kid.balance ? 'green' : 'red' }}>
                      <AsBux>{balance}</AsBux>
                    </strong>
                  </Header.Subheader>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Header>
        </Container>
      </FloatingHeader>

      <Menu pointing secondary>
        <Menu.Item
          name='Chores'
          active={activeTab === 'chores'}
          onClick={() => setActiveTab('chores')}
        />

        <Menu.Item
          name='Rewards'
          active={activeTab === 'rewards'}
          onClick={() => setActiveTab('rewards')}
        />

        <Menu.Item
          name='Notes'
          active={activeTab === 'notes'}
          onClick={() => setActiveTab('notes')}
        />
      </Menu>

      <ContentArea>
        <Segment style={{ display: activeTab === 'chores' ? 'block' : 'none' }}>
          {filteredChores && filteredChores.length > 0 ? filteredChores.map((chore) =>
            <ChoreForm
              chore={chore}
              key={chore.id}
              kid={kid}
              onComplete={(chore) => setChoreBalance(choreBalance + parseFloat(chore.value))}
              onUncomplete={(chore) => setChoreBalance(choreBalance - parseFloat(chore.value))}
              onChange={handleChoreChanged}
            />
          ) : (
            <p>No chores assigned to this kid.</p>
          )}
        </Segment>
  
        <Segment style={{ display: activeTab === 'rewards' ? 'block' : 'none' }}>
          {rewards && rewards.length > 0 ? rewards.map((reward) =>
            <RewardForm
              reward={reward}
              key={reward.id}
              onComplete={(reward) => setRewardBalance(rewardBalance + parseFloat(reward.value))}
              onUncomplete={(reward) => setRewardBalance(rewardBalance - parseFloat(reward.value))}
              onChange={handleRewardChanged}
            />
          ) : (
            <p>No chores assigned to this kid.</p>
          )}
        </Segment>
  
        <Segment style={{ display: activeTab === 'notes' ? 'block' : 'none' }}>
          <Form>
            <Form.TextArea onChange={(e, d) => {setFormData({ ...formData, notes: d.value })}}></Form.TextArea>
          </Form>
        </Segment>
      </ContentArea>

      <FloatingFooter>
        <Container>
          <Grid>
            <Grid.Row columns={2} verticalAlign="middle">
              <Grid.Column>
                <Button icon><Icon name="setting" /></Button>
              </Grid.Column>

              <Grid.Column textAlign="right">
                <Button onClick={() => history.push(`/kids/${kid.id}`)}>Cancel</Button>
                <Button onClick={handleSubmitChart} color="green" loading={saving}>Save</Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </FloatingFooter>
    </>
  )
}