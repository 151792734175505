import React from 'react';
import moment from 'moment';

import { useSelector } from 'react-redux';
import { useFirestoreConnect, isLoaded } from 'react-redux-firebase';
import { useParams, useHistory, Route, Switch, Link } from 'react-router-dom';
import { Header, Grid, Container, Button, Icon, Segment, Divider } from 'semantic-ui-react';

import { UpdateBalance } from './Kid.UpdateBalance';
import { RecentCharts } from './Kid.RecentCharts';
import { DailyChartForm } from './Kid.DailyChartForm';
import { Stats } from './Kid.Stats';
import { KidChart } from './Kid.Chart';

export const Kid = (props) => {
  const params = useParams()
  const history = useHistory()
  
  useFirestoreConnect([
    `kids/${params.id}`,
    {
      collection: 'charts',
      where: ['kid', '==', params.id],
      orderBy: ['timestamp', 'desc'],
      limit: 7
    }
  ])
  
  const kid = useSelector(({ firestore: { data } }) => data.kids && data.kids[params.id])
  const { charts } = useSelector((state) => state.firestore.ordered)

  if (!isLoaded(kid) || !isLoaded(charts)) { return <div>Loading...</div> }

  // We don't get the id from the database for some stupid reason. Assign it back to the object from params. Wat.
  kid.id = params.id

  return (
    <Container>
      <Route exact path={["/kids/:id", "/kids/:id/charts/:chartId"]}>
          <Header style={{ marginBottom: '3em' }}>
            <Grid>
              <Grid.Row columns={2} verticalAlign="middle">
                <Grid.Column>
                  <Route exact path="/kids/:id">
                    {kid.name}
                  </Route>

                  <Route exact path="/kids/:id/charts/:chartId" render={(({ match }) => {
                    const chart = charts.filter(c => c.id === match.params.chartId)[0]
                    const chartDate = moment.unix(chart.timestamp.seconds).format('dddd, MMMM Do')
                    return <><Link to={`/kids/${kid.id}`}>{kid.name}</Link> / {chartDate}</>
                  })} />
                </Grid.Column>


                <Grid.Column textAlign="right">
                  <Route exact path="/kids/:id">
                    <UpdateBalance
                      id={params.id}
                      initialBalance={kid.balance}
                      label={(
                        <>
                          <Icon name="dollar" color="green" />
                          Edit Balance
                        </>
                      )}
                    />
                  </Route>

                  <Button onClick={() => history.push(`/kids/${params.id}/new-chart`)}>
                    <Icon name="add" color="blue" />
                    Daily Chart
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Header>
      </Route>

      <Switch>
        <Route exact path="/kids/:id">
          <Segment>
            <Stats kid={kid} charts={charts} />
            <Divider />
            <RecentCharts kid={kid} charts={charts} />
          </Segment>
        </Route>

        <Route exact path="/kids/:id/new-chart">
          <DailyChartForm kid={kid} />
        </Route>

        <Route exact path="/kids/:id/charts/:chartId">
          <KidChart kid={kid} charts={charts} />
        </Route>
      </Switch>
    </Container>
  )
}