import React, { useState } from 'react'
import ReactMarkdown from 'react-markdown'

import { useParams } from 'react-router'
import { Stats } from './Kid.Stats'
import { List, Icon, Segment, Divider, Menu, Header, Label, Message } from 'semantic-ui-react'
import { AsBux } from '../AsBux'

export const KidChart = ({ kid, charts }) => {
  const params = useParams()

  const [activeTab, setActiveTab] = useState('chores')
  
  const chart = charts.filter(c => c.id === params.chartId)[0]
  chart.chores_completed = chart.chores_completed.sort(a => a.type === 'daily' ? -1 : 0)
  const chartDiff = chart.summary.earned - chart.summary.spent

  const choreTypeToColor = (type) => {
    let headerColor

    switch (type) {
      case 'daily':
        headerColor = 'orange'
        break;
  
      case 'weekday':
        headerColor = 'green'
        break;
  
      case 'weekend':
        headerColor = 'blue'
        break;
  
      default:
        break;
    }

    return headerColor
  }

  return (
    <>
      <Segment style={{ marginBottom: '3em' }}>
        <Stats kid={{ balance: chartDiff }} charts={[chart]} />
        <Divider />

        <Menu pointing secondary>
          <Menu.Item
            name='Chores Completed'
            active={activeTab === 'chores'}
            onClick={() => setActiveTab('chores')}
          />

          <Menu.Item
            name='Rewards Collected'
            active={activeTab === 'rewards'}
            onClick={() => setActiveTab('rewards')}
          />

          <Menu.Item
            name='Notes'
            active={activeTab === 'notes'}
            onClick={() => setActiveTab('notes')}
          />
        </Menu>

        <div style={{ display: activeTab === 'chores' ? 'block' : 'none' }}>
          {chart.chores_completed.length &&
            <>
              {chart.chores_completed.map(chore =>
                <React.Fragment key={chore.id}>
                  <Header color={choreTypeToColor(chore.type)}>
                    {chore.type.charAt(0).toUpperCase() + chore.type.slice(1)}
                    {': '}
                    {chore.name}
                    {' '}
                    <Label color={chore.completed ? 'green' : ''}>
                      {chore.completed && <Icon name="thumbs up" />}
                      <AsBux>{chore.completed ? chore.value : 0}</AsBux>
                    </Label>
                  </Header>
                  <List>
                    {chore.tasks.map(task =>
                      <List.Item key={task.name}>
                        <Icon
                          name={task.complete ? 'checkmark' : 'close'}
                          color={task.complete ? 'green' : 'red'}
                        />
                        {' '}
                        {task.name}
                      </List.Item>
                    )}
                  </List>
                </React.Fragment>
              )}
            </>
          }
        </div>

        <div style={{ display: activeTab === 'rewards' ? 'block' : 'none' }}>
          <Message color="green">
            <p>Allowance earned from chores: <AsBux>{chart.summary.earned}</AsBux></p>
          </Message>

          {chart.rewards_collected.map(reward =>
            <Message>
              <p>{reward.name}: {reward.quantity}x</p>
            </Message>
          )}
        </div>

        <div style={{ display: activeTab === 'notes' ? 'block' : 'none' }}>
          <ReactMarkdown source={chart.notes} />
        </div>
      </Segment>
    </>
  )
}