import React from 'react';

import { useSelector } from 'react-redux';
import { isLoaded, isEmpty } from 'react-redux-firebase';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import { Layout } from '../Layout';
import { Kids } from '../Kids';
import { Kid } from '../Kid';

export const App = () => {
  const auth = useSelector((state) => state.firebase.auth)

  if (!isLoaded(auth)) { return <div>Loading...</div>}

  return (
    <Router>
      <Layout>
          {isEmpty(auth) ? (
            <img src="https://media.giphy.com/media/xiJXFeua9tMqc/giphy.gif" alt="chortle chortle" />
          ) : (
            <Switch>
              <Route exact path="/" component={Kids} />
              <Route path="/kids/:id" component={Kid} />
            </Switch>
          )}
      </Layout>
    </Router>
  );
}
