import React, { useState } from 'react';
import { useFirestore } from 'react-redux-firebase';

import {
  Button,
  Form,
  Header,
  Icon,
  Modal
} from 'semantic-ui-react'

export const UpdateBalance = ({ id, initialBalance, label }) => {
  const firestore = useFirestore()
  const [balance, setBalance] = useState(initialBalance)
  const [isModalVisible, setIsModalVisible] = useState(false)

  const handleUpdateBalance = (e) => {
    e.preventDefault()
    setIsModalVisible(false)
    firestore.update(`kids/${id}`, { balance })
  }

  return (
    <Modal
      trigger={<Button onClick={() => setIsModalVisible(true)}>{label}</Button>}
      open={isModalVisible}
      onClose={() => setIsModalVisible(false)}
      size="mini"
      closeIcon
    >
      <Header icon='dollar' content='Balance' />
      <Modal.Content>
        <Form.Field>
          <Form.Input fluid autoFocus type="text" defaultValue={initialBalance} onChange={(e) => setBalance(e.target.value)} placeholder="Balance" />
        </Form.Field>
      </Modal.Content>

      <Modal.Actions>
        <Button color='green' onClick={handleUpdateBalance}>
          <Icon name='checkmark' /> Save new balance
        </Button>
      </Modal.Actions>
    </Modal>
  )
}